.content_wrap, .content_container {
  width: 1300px;
  max-width: 100%;
}

@media (max-width: 1400px) {
  .content_wrap, .content_container {
    width: 1300px;
  }
  .course-page{
    .banner{
      .hero-content{
        .c-title{
          font-size: 22px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .content_wrap, .content_container {
    width: 1100px;
  }
  .course-page{
    .banner{
      .hero-content{
        .c-title{
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .login-button{
    padding-left: 15px;
  }
  .signup-button{
    padding-left: 15px;
    max-width: 80%;
    margin: 0 auto !important;
  }
  .content_wrap, .content_container {
    width: 1000px;
  }
}

@media (max-width: 900px) {
  .content_wrap, .content_container {
    width: 800px;
  }

  .course-page {
    .banner{
      .header-box{
        position: relative;
        right: 0;
        .btn{
          white-space: normal;
        }
        .enroll-btn{
          width: 100%;
          max-width: 375px;
        }
      }
    }
    .video-container{
      margin-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      .content{
        flex-direction: column-reverse;
        padding-bottom: 10px;
        padding-top: 20px;
      }

      .video-self{
        min-width: 100%;
        iframe{
          height: 400px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content_wrap, .content_container {
    width: 700px;
  }
  .course-page {
    .video-container{
      .video-self{
        iframe{
          height: 350px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .content_wrap, .content_container {
    width: 500px;
  }
  .course-page {
    .video-container{
      .video-self{
        iframe{
          height: 250px;
        }
      }
    }
  }
}
