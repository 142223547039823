.email-subscription-page{
  &__content{
    background-image: url('/assets/images/desktop-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .success-msg{
    color: #fb7c41 !important;
    font-size: 12px !important;
  }

  .video__wrapper__video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }

  .app-title{
    font-size: 3rem;
  }

  .sd-color{
    color: #fb7c41;
  }

  .mobile-container {
    margin: 0 auto;
    max-width: 500px;
    min-width: 300px;
  }

  .hero-image-desktop {
    position: relative;
    display: none;
  }


  .hero-banner {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .hero-banner .logo-wrapper {
    height: auto;
    margin: 0 auto;
    padding: 1rem 0;
    width: auto;
  }

  .logo-wrapper img {
    display: block;
    height: 5.5rem;
    margin: 0 auto;
    width: auto;
  }

  .hero-banner .hero-image {
    background-image: url('/assets/images/retro-app-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 376px;
    width: 100%;
  }

  /* ---------- TEXT INFO DECLERATIONS ---------- */
  .text-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  .text-info > * {
    padding: 0 2.5rem 1rem;
  }

  /* intended for the first h2 within .text-info */
  .text-info h2:nth-child(1) {
    color: #fb7c41;
    font-weight: normal;
    padding-top: 1.8rem;
    padding-bottom: 1rem;
  }

  .text-info h2 {
    font-size: 3.5rem;
    font-weight: bold;
    color: #423A3A;
    letter-spacing: 5px;
    text-transform: uppercase;

    &.sm{
      font-size: 2rem;
    }
  }

  .text-info p {
    color: #333333;
    line-height: 24px;
  }

  .text-info .email-signup {
    margin: 1rem 0 6rem;
    position: relative;
    width: 100%;
  }

  .email-signup .email-input {
    border: 1px solid rgba(51, 51, 51, .7);
    border-radius: 3px;
    padding: 1rem 1.5rem;
    opacity: .5;
    width: 100%;
  }

  .email-signup .email-input:focus {
    background-color: #fff;
    outline: 2px solid #333333;
    opacity: 1;
  }

  .email-signup .email-submit {
    background-color: #333333;
    border-bottom: 1px solid #333333;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    opacity: 1;
    padding: 1rem 1.5rem;
    position: absolute;
    right: 0;
    margin-right: 2.5rem;
    z-index: 10;
  }
}

@media only screen and (min-width: 768px) {

  .email-subscription-page{

    .mobile-container {
      width: 45vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .hero-banner {
      display: none;
    }
    .hero-banner .logo-wrapper {
      margin-left: 3.5rem;
      width: 100%;
      display: flex;
      align-items: start;
      flex-direction: column;
    }
    .logo-wrapper img {
      margin: 0 0 0;
      padding-left: 2.5rem;
    }

    .hero-image {
      display: none;
    }

    .text-info {
      text-align: left;
      align-items: start;
    }

    .text-info h2 {
      font-size: 4.5rem;
    }

    .text-info p {
      font-size: 16px;
    }

    .text-info input {
      font-size: 16px;
    }

    .text-info .email-signup {
      margin: 1rem 0 7rem;
    }

    .hero-image-desktop {
      display: block;
      height: 100%;
      width: 55vw;
    }

    .hero-image-desktop img {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .email-signup .email-submit {
      border-bottom: 2px solid #333333;
    }
  }
}