.getscrumban-page{
  .d-list{
    display: list-item;
  }
  .header {
    text-align: center;
  }
  .subheader {
    font-weight: 300;
    padding: 10px 0;
  }
  .hero-image {
    width: 100%;
    height: auto;
  }
  .features {
    padding: 40px 0;
  }
  .feature {
    padding: 20px 0;
  }
  .cta {
    background-color: #f8f9fa;
    padding: 40px 0;
    text-align: center;
  }
  .cta-button {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
  }
  .cta-button:hover {
    background-color: #0056b3;
    color: white;
    text-decoration: none;
  }

  .features-images{
    .features-image{

      .image-wrapper{
        border-radius: 10px;
        background-color: #f5f5f5;
        padding: 20px;
      }
      img{
        max-width: 100%;
      }
    }
  }

  .stripe-images{
    .image-wrapper {
      padding: 10px;
      img{
        max-width: 100%;
      }
    }
  }
}