.justify-content-center{
    justify-content: center;
}

.align-items-center {
    align-items: center;
}
.flex-wrap{
    flex-wrap: wrap;
}
.gap-4{
    gap: 18px;
}
.gap-5{
    gap: 25px;
}