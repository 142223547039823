
@media only screen and (max-width: 1440px) {

  .home-banner__video{
    .content{
      h1{
        font-size: 22px;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .home-ppage-video {
    .home-masthead{
      padding-top: 0px;
    }
  }

  .home-banner__video{
    .signup-form{
      margin-top: 60px;
    }

    .content {
      h1 {
        font-size: 22px;
        margin-bottom: 5px;
      }
    }
    .sc_testimonials{
      .sc_testimonials_item_content{
        margin-top: 0;
        p{
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 992px) {
  .footer-empty-cols{
    display: none !important;
  }
  .sdtabs {
    max-width: 90vw;
    padding: 20px;
    .sdtabs-nav{
      display: flex;
      justify-content: center;
    }
    .sdtab-content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column-reverse;
      align-items: center;

      .tab-terminal{
        margin: 40px 0;
        .bg_image {
          left: -160px;
          right: -50px;
          top: -70px;
          bottom: -65px;
        }
      }
      .tab-content{
        margin-right: 0;
        margin-top: 40px;
        .title{
          font-size: 20px;
          line-height: 30px;
        }
        .subtitle{
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .home-ppage-video {
    .home-masthead{
      padding-top: 0px;
    }
  }

  .home-banner__video{
    flex-direction: column-reverse;
    padding: 10px;
    .signup-form{
      margin-top: 30px;
    }
    .video{
      width: calc(100% - 20px);
      min-width: 100%;
      padding-top: 20px;
      .video__wrapper__video{
        width: 100%;
        max-width: 100%;
        height: auto;
        video{
          width: 100% !important;
          height: auto !important;
          margin: 0;
        }
      }
    }
    .content {
      width: 100%;
      max-width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
      #email_address{
        max-width: 300px;
      }
      .solid-button{
        max-width: 300px;
      }
      h1 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 5px;
        min-height: unset;
      }
    }
  }

  .home-banner__video{
    .signup-form{
      margin-top: 60px;
    }
    .sc_testimonials{
      .sc_testimonials_item_content{
        margin-top: 0;
        p{
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .content {
      h1 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 5px;
      }
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {

  .sdtabs {
    max-width: 95vw;
    padding: 20px;
  }
  .hide-mobile{
    display: none !important;
  }

  .home-ppage-video {
    .home-masthead{
      padding-top: 0px;
    }
  }
  .home-banner__video{
    .signup-form{
      margin-top: 40px;
    }
    .sc_testimonials{
      display: none;
    }
    .content {
      padding-top: 50px;
      h1 {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 5px;
      }
    }
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .float-trial-button{
    display: none;
  }
  .home-ppage-video {
    .home-masthead{
      padding-top: 0px;
    }
  }
  .home-banner__video{
    flex-direction: column-reverse;

    .signup-form{
      margin-top: 30px;
    }
    .video{
      width: calc(100% - 20px);
      min-width: 100%;
      padding-top: 20px;
      .video__wrapper__video{
        width: 100%;
        max-width: 100%;
        height: auto;
        video{
          width: 100% !important;
          height: auto !important;
          margin: 0;
        }
      }
    }
    .content {
      width: 100%;
      h1 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 5px;
      }
    }
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//   .home-ppage-video {
//     .home-masthead{
//       padding-top: 40px;
//       .signup-form{
//         margin-top: 60px;
//       }
//     }
//     .content {
//       h1 {
//         font-size: 35px;
//         line-height: 38px;
//         margin-bottom: 5px;
//       }
//     }
//   }
// }