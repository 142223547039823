:root{
  --color-canvas-default: #ffffff;
  --color-fg-default: #24292f;
  --color-mktg-btn-shadow-outline: rgb(0 0 0 / 15%) 0 0 0 1px inset;
  --color-mktg-btn-shadow-hover-muted: rgb(0 0 0 / 70%) 0 0 0 2px inset;
  --color-success-fg: #3fb950;
}

:root .font-mktg [data-color-mode=dark] {
  --color-fg-default: #fff !important;
}

body{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

#html5-lightbox-overlay{
  background-color: #1b2f644d !important;
}

.fs-80{
  font-size: 80%;
}
.fs-90{
  font-size: 90%;
}

.w-100{
  width: 100% !important;
}

.html5-elem-box{
  border-radius: 12px;
}

.menu-item {
  a {
    text-decoration: none;
  }
}

.login-button {
  padding-right: 15px;
}

.signup-button {
  a{
    background-color: #fb7c41;
    border-radius: 2px;
    padding: 6px 12px !important;
    margin: 7px 0px;
  }
}

.sc_layouts_logo_default{
  img{
    width: 150px;
  }
}

.sc_layouts_menu_nav > li ul {
  padding-left: 10px;
  padding-right: 10px;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

blockquote {
  &.sm{
    p{
      font-size: 14px;
    }
  }
}
.author-image {
  width: 100px;  /* adjust as needed */
  height: 100px; /* adjust as needed */
  object-fit: cover; /* this will ensure the aspect ratio of the image is maintained */
}
.image-container {
  display: flex;
  justify-content: space-between;
}
.site-header-wrapper{
  .site-header-logo{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.sc_button.sc_button_size_small, .theme_button.theme_button_small {
  padding: 1em 4em;
}

.content_wrap{
  &.home{
    width: 100% !important;
  }
  .content{
    padding: 50px 0;
    &.home{
      padding-top: 0;
    }
  }
}

.scheme_default .vc_toggle.vc_toggle_arrow.vc_toggle_active  .vc_toggle_title h4{
  color: #333 !important;
}
.scheme_default .vc_toggle.vc_toggle_arrow.vc_toggle_active{
  border: 1px solid #dedede;
  background-color: #f7f8fa;
}

.top-container{
  width: 100%;
  .banner{
    padding: 60px 40px;
    color: #fff;
    // background-image: linear-gradient(90deg, #212b9f, #757cd0);
    // background-image: url(/wp-content/uploads/2018/12/header-no-pic.png?id=3124) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

  }
  .c-title{
    color: #fff;
    padding: 0;
    margin: 0 0 25px 0;
    font-size: 32px;
  }
  .c-desc{
    font-size: 14px;
    line-height: 18px;
  }
}

.no-float-link{
  .float-trial-button{
    display: none;
  }
}

.float-trial-button{
  height: 48px;
  width: 346px;
  position: fixed;
  right: -240px;
  top: 75%;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 5px 5px;
  z-index: 999;
  box-sizing: border-box;
  background: #fb7c40;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: -4px 4px 12px -3px #3333336b;
  cursor: pointer;
  transition: right .5s ease;

  &:hover{
    right: -10px;
  }
  .trial-days{
    flex-basis: 95px;
    min-width: 95px;
    padding-left: 5px;
    span{
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      text-align: center;
    }
  }
  .arrow{
    font-size: 20px;
    color: #fff;
    margin-right: 8px;
  }
  .lable{
    text-transform: uppercase;
    background: #fff;
    border-radius: 4px;
    padding: 1px 10px;
    text-align: center;
    a{
      font-size: 18px;
      line-height: 20px;
      display: block;
      color: #fb7c40;
      font-weight: 600;
      text-decoration: none;
    }
  }
}


.implications{
  border-top: 1px solid #333;
  padding-top: 10px;
  .citation{
    font-weight: 500;
    margin: 0 0 13px 5px;
    font-size: 12px;
    line-height: 16px;
  }
}
.disclaimer{
  font-style: italic;
  font-weight: 600;
}

.author-wrapper{
  margin: 20px 0 100px 0;
  display: flex;
  .avatar{
    margin-right: 15px;
    min-width: 200px;
    h5{
      margin: 0;
      font-size: 14px;
    }
    img{
      width: 200px;
      border-radius: 5px;
    }
  }
  .bio{
    line-height: 20px;
    font-style: italic;
    font-weight: 600;

    blockquote{
      margin: 0;
      padding: 20px;
    }
  }
}

.adv-member-avatar{
  width: 200px;
  height: 220px;
}
.team-member-avatar{
  width: 200px;
  height: 195px;
}
.mobile-app-collage{
  padding: 0 15px;
  img{
    width: 100%;
    border-radius: 15px;
  }
}

.have-a-question{
  height: 55px;
  width: 265px;
  position: fixed;
  right: -212px;
  top: 50%;
  border: 1px solid #dedede;
  border-radius: 25px;
  padding: 5px 5px;
  z-index: 999;
  box-sizing: border-box;
  background: #fb7c40;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: -4px 4px 12px -3px #3333336b;
  cursor: pointer;
  transition: right .5s ease;

  &:hover{
    right: -25px;
  }
  .icon{
    flex-basis: 45px;
    min-width: 45px;
    img{
      width: 45px;
      height: 45px;
    }
  }
  .lable{
    a{
      font-size: 20px;
      line-height: 20px;
      color: #fff;
      font-weight: 700;
    }
  }
}
.fa-15x {
  font-size: 1.5em;
}

.page-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
}

.legal-page-header{
  background: #4f4f4f;
}

.menu-item-has-children{
  .visible{
    visibility: visible !important;
  }
  ul{
    li{
      a{
        padding: 5px 2px;
      }
    }
  }
}

@keyframes fade {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.flex-container {
  display: flex;
  align-items: center; /* This will vertically center the image and the text */
}

.responsive-image {
  width: 25%;
  max-height: 300px; /* Adjust this value as needed */
  object-fit: contain; /* This will make sure the image scales correctly */
}

.text-content {
  width: 75%;
  font-size: 1.5em; /* Increase the font size */
  padding-left: 20px; /* Add space between the text and the image */
}
.highlighted-list {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.highlighted-list li {
  margin-bottom: 10px;
  font-weight: bold;
}