#connect-btn {
  display: inline-block !important;
}
.anim-home-layout{
  .wide-block{
    background-color: transparent;
  }
}
.home-git-log-center{
  &:after{
    z-index: 1 !important;
  }
  > div{
    position: relative;
    z-index: 2;
  }
}

.quick-email{
  input{
    border-radius: 6px;
    padding: 8px 15px;
  }
}

.single-slide {
  .slide-content-wrapper{
    .home-git-icon{
      display: none;
    }
  }
}

.home-banner-testimonials{
  .testimonial-wrapper {
    &.testimonial-container{
      background: transparent;
      border: none;
      color: #888;
      padding: 0;
      margin: 0;
      max-width: 80vw;

      .testimonial{
        font-size: 14px;
        margin-bottom: 4px;
        margin-top: 10px;
        line-height: 20px;
      }
      .user{
        margin-top: 10px;
        background: transparent;
        justify-content: flex-start;
        img{
          width: 50px;
          height: 50px;
        }
        .role{
          margin-bottom: 0;
          margin-top: 5px;
        }
        .username{
          color: #333;
        }
      }
    }
  }
  .vc_empty_space, .sc_title{
    display: none;
  }
  .sc_testimonials_item{
    padding: 5px 15px;
    background-color: #ffffff00 !important;
    .sc_testimonials_item_pictogram{
      display: none;
    }
    .sc_testimonials_item_author{
      margin-top: 15px;
    }
    .sc_testimonials_item_content, .sc_testimonials_item_author_title{
      color: #fff !important;
    }
  }
}

.text-center{
  text-align: center;
}

.home-banner__video{
  .content{
    position: relative;
    z-index: 3;
    h1{
      font-size: 26px;
      margin-bottom: 10px;
      line-height: 35px;
      min-height: 66px;
      span{
        color: #e88026;
        line-height: 35px;
        span{
          height: 35px;
          line-height: 35px;
          transition: margin .2s;
          text-wrap: nowrap;
          text-wrap: nowrap;
        }
      }
    }
    p{
      font-size: 18px;
    }
    a{
      width: 100%;
      float: left;
    }
    .solid-button{
      position: relative;
      background-color: #fb7c41;
      border: 2px solid #fb7c41;
      color: #fff;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 9px 18px;
      max-width: 355px;
      width: 100%;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      border-radius: 2px;
      letter-spacing: 1px;
      text-align: center;
      float: left;
      display: block;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;

      &.alt{
        background-color: #4db9fe;
        border-color: #4db9fe;
      }
    }
  }
}

.home-masthead{
  &.features{
    border-radius: 12px;
    box-shadow: 2px 2px 12px 0 #33333330;
    margin-top: -30px;
    video{
      border-radius: 12px;
    }
  }
}

.home-ppage-video{
  margin-top: -30px;
  .home-masthead{
    background-position: center center;
    height: calc(60vh - 10px);
    min-height: 325px;
    overflow: hidden;
    background-color: #0c1116;
    background-size: cover;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 5;
    width: 100%;
    position: relative;
  }
  .content{
    color: #fff;
    position: relative;
    z-index: 3;
    h1{
      color: #fff;
      font-size: 42px;
      margin-bottom: 30px;
      line-height: 60px;
    }
    p{
      font-size: 18px;
    }
    a{
      width: 100%;
      float: left;
    }
    .solid-button{
      position: relative;
      background-color: #fb7c41;
      border: 2px solid #fb7c41;
      color: #fff;
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 9px 18px;
      max-width: 355px;
      width: 100%;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      border-radius: 2px;
      letter-spacing: 1px;
      text-align: center;
      float: left;
      display: block;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;

      &.alt{
        background-color: #4db9fe;
        border-color: #4db9fe;
      }
    }
  }
  .video-overlay{
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2a4cbede;
  }
  video, iframe, .gif-image{
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    z-index: -100;
    display: inline-block;
    vertical-align: baseline;
    object-fit: cover;
  }

  .full-video-btn{
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 3;
    a{
      cursor: pointer;
      text-decoration: none;
    }
    img{
      width: 50px;
    }
  }
}

.companies{
  padding: 50px 20px;
  border-radius: 12px;

  .certificate{
    margin: 0 auto;
    width: 220px;
    img{
      width: 100%;
      border: 1px solid #dedede;
      border-radius: 8px;
    }
  }
  .companies-logo{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
    justify-content: center;
    .image-wrapper{
      padding: 8px 10px;
      border-radius: 12px;
      background-color: #fff;

      img{
        width: 80px;
        filter: grayscale(1);
        opacity: 0.7;
      }
    }
  }
}


.home-banner{
  &__video{
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    padding: 0 10px 0 50px;
    width: 100%;
    color: inherit;
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    .content{
      min-width: 340px;
      padding-top: 120px;
      max-width: 35%;
      .typed-header-wrapper{
        display: flex;
        align-items: center;
        gap: 12px;
      }
      .typed-header{
        height: 35px;
        display: flex;
        overflow: hidden;
        flex-direction: column;
      }
    }
    .signup-form-wrapper{

    }
    .video{
      padding-top: 40px;
      flex-grow: 1;
      &__wrapper{
        padding: 0;
        margin: 0;
        background: 0;

        &__video{
          position: relative;
          background-color: transparent;
          border-radius: 7px;
          overflow: hidden;
          margin: 0 auto;
          video{
            width: 100%;
          }
        }
      }
    }
  }
}

.home-banner-testimonials{
  max-width: 900px;
  margin: 0 auto;
}

.home-signup-form{
  input[type="email"] {
    padding: 10px 25px;
    margin-bottom: 20px;
  }
  input[type="submit"] {
    padding: 10px 25px;
  }
}


.case-studies{
  margin-top: 100px;
  .case-studies-wrapper{
    &__media{
      max-height: 200px;
      overflow: hidden;
      margin-bottom: 30px;
    }
    &__study{
      float: left;
      align-items: center;
      padding: 20px;
    }
  }
}

.home-events-label{
  text-align: center;
}

.cal-events{
  .cal-event-alt{
    min-width: 400px;
    max-width: 400px;
    border-radius: 10px;
    background-color: #f4f4f4;

    .cal-info{
      padding: 4px 10px;

      .btn-primary{
        color: #fff;
        background-color: #e88026;
      }
    }

    .cal-date{
      padding: 10px;
      min-width: 80px;

      .cal-date-text{
        color: #fff;
        background-color: #e88026;
        width: 60px;
        height: 70px;
        border-radius: 12px;
        padding: 5px 5px;
        p{
          margin: 0;
          font-size: 20px;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    .event-title{
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
    }

    .event-sub-title{
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}