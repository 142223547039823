
.course-page{

  .case-study-box{
    padding: 10px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #dedede;

    .grid-item{
      height: 200px;
      width: 100%;
      background-position: center;
      background-size: cover;
      margin-bottom: 10px;
    }
    .grid-item-title{
      a{
        color: #377cff;
      }
    }
  }
  .banner{
    .video-overlay{
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #3333338f;
      z-index: 2;
    }
    .content_wrap{
      z-index: 5;
      .content{
        padding: 0px 0 50px 0;
        .hero-content{
          h2{
            color: #dcdcdc;
          }
          .book-seat-btn{
            font-size: 18px;
          }
        }
      }
      .header-box{
        .btn{
          color: #fff;
        }
      }
    }
  }

  #about{
    p{
      font-size: 18px;
    }
    h6{
      font-size: 18px;
    }
  }

  del{
    color: inherit;
  }
  .course-info{
    >.row{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  p{
    line-height: 26px;
  }
  .mt-6{
    margin-top: 5em;
  }
  .container{
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  min-height: 60vh;

  .c-content{
    width: 1440px;
  }
  h3{
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
  }
  h4{
    font-weight: 700;
    font-size: 20px;
  }
  h5{
    font-weight: 700;
    font-size: 18px;
  }
  h6{
    font-weight: 700;
    font-size: 16px;
  }

  .tutor-wrapper{
    box-shadow: inset 0 0 0 1px #F2F2F2;
    display: flex;
    .about{
      flex: 1 1 auto;
      padding: 16px 20px;
      flex-direction: column;
      justify-content: center;
      display: flex;
      h4{
        font-size: 16px;
      }
      p{
        line-height: 18px;
        font-size: 14px;
      }
    }
    .avatar{
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
  }

  .navs-links{
    border-bottom: 1px solid #dedede;
  }
  .top-container{
    .banner{
      padding: 60px 40px;
      color: #fff;
      background-image: linear-gradient(90deg, #2f414a, #87b2c7);
    }
    .c-title{
      color: #fff;
      padding: 0;
      margin: 0 0 25px 0;
      font-size: 32px;
    }
    .c-desc{
      font-size: 14px;
      line-height: 18px;
    }
  }
  .module-title{
    font-size: 16px !important;
  }
  .vc_toggle.vc_toggle_arrow{
    padding: 15px;
  }
  .vc_toggle.vc_toggle_arrow .vc_toggle_content{
    margin-top: 15px;
  }

  .module-title{
    .module-dur{
      width: 25px;
      display: inline-block;
      color: #888;
    }
    .module-info {
      display: block;
      margin-top: 15px;
      margin-left: 7.4em;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .course-frame{
    border: none;
    width: 100%;
    height: 80vh;
  }

  .learn-block{
    // border: 1px solid #dedede;
    // border-radius: 4px;
    // padding: 10px 15px;
    &.skills{
      margin-top: 20px;

      ul{
        gap: 10px;
        li{
          background-color: #eee;
          padding: 4px 20px;
          border-radius: 25px;
          width: auto;
        }
      }
    }
    h5{
      margin-top: 0;
    }

    ul{
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      gap: 40px;
      padding: 0;
      li{
        display: flex;
        width: calc(50% - 40px);
        .check-icon{
          margin: 4px 10px 0 0;
        }
      }
    }
  }

  .highlights{
    gap: 15px;
    .highlight{
      // border: 1px solid #dedede;
      padding: 4px 2px;
      border-radius: 8px;
      .highlight-info{
        p{
          line-height: 16px;
        }
      }
      .icon{
        margin-right: 15px;
        color: #fa7c41;
        .fas{
          font-size: 23px;
          padding: 7px;
          border: 1px solid #dedede;
          border-radius: 50%;
          width: 43px;
          height: 43px;
          text-align: center;
          line-height: 28px;
        }
      }
    }
    &.sm{
      font-size: 14px;
      gap: 5px;
      .highlight{
        .icon{
          margin-right: 5px;
        }
        .fas{
          line-height: 15px;
          width: 20px;
          height: 20px;
          font-size: 12px;
          padding: 2px;
        }
      }
    }
  }
  .sc_testimonials{
    .sc_testimonials_item_pictogram{
      img{
        border-radius: 8px;
      }
    }
  }
  .no-bold{
    font-weight: 500;
  }

  .certificate-block{
    background: #f4f4f4;
    .certificate{
      .text{
        max-width: 400px;
        img{
          width: 80px;
        }
      }
      .image{
        img{
          width: 200px;
        }
      }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-container{
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 12px;
    margin-top: 40px;

    .video-info{
      font-size: 18px;
    }
    .video-self{
      min-width: 60%;
      iframe{
        width: 100%;
        height: 430px;
        overflow: hidden;
        border-radius: 12px;
      }
    }
  }
}


@media (min-width: 576px) {
  .course-page {
    .container {
      max-width: 540px;
    }
  }
}
@media (min-width: 768px) {
  .course-page {
    .container {
      max-width: 720px;
    }
  }
}
@media (min-width: 992px) {
  .course-page {
    .container {
      max-width: 960px;
    }
  }
}
@media (min-width: 1200px) {
  .course-page {
    .container {
      max-width: 1440px;
    }
  }
}
