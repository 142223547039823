.form-wrapper{
  position: relative;
  padding: 50px;

  .errors-list{
    li{
      background: #fff;
      border-radius: 4px;
      text-align: left;
      padding: 1px 10px;
      border: 1px solid #ff2626;
    }
  }
  .form-overlay, #formMsgOverlay{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(191, 191, 191, 0.22);
    display: flex;
  }

  #formMsgOverlay{
    background-color: #fff;
    display: flex;
  }
}
#trialRequestForm{
  width: 300px;
  margin: 0px auto;
  position: relative;
  .form-control{
    padding: 8px 15px;
    border-radius: 12px;
  }
  .form-group{
    text-align: left;
    margin-bottom: 10px;
  }
  .btn{
    padding: 10px 15px !important;
    border-radius: 8px !important;
  }
  .select_container{
    border: 2px solid #e5e7ed;
    border-radius: 8px;
  }
}

.pricing_wrap{
  background-color: #fcfcfc !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
.bold{
  font-weight: bold;
}
.pricing_page {
  &.iframe{
    height: 1200px;
    overflow-y: auto;
    overflow-x: hidden;
    .features{
      .features-cat{
        .features-row{
          &.cat{
            position: sticky;
            top: 0;
            z-index: 2;
            background: #fff;
          }
        }
      }
    }
  }
  .cToolTip_{
    border-bottom: 1px dashed #9a9a9a;
  }
  .overflow-visible{
    overflow: visible;
  }
  .pricing-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
    .question-mark {
      position: relative;
      display: inline-flex;
      width: 18px;
      height: 18px;
      margin-top: 0;
      margin-left: 4px;
      padding-top: 1px;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      border-radius: 50%;
      background-color: #2cb4ff;
      color: #fff;
      font-size: 12px;
      line-height: 1em;
      font-weight: 700;
      text-align: left;
      letter-spacing: .3px;
      cursor: pointer;
      opacity: .75;
    }
    .tooltiptext {
      visibility: hidden;
      width: 400px;
      position: absolute;
      z-index: 99;
      left: 100%;
      padding-left: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      &.sm{
        font-size: 13px;
        font-weight: 400;
        .content-area {
          line-height: 20px;
        }
      }
      &.bottom{
        top: 100%;
        transform: translateX(-200px);
        left: 50%;
        .content-area {
          &:after{
            top: -6px;
            left: 45%;
            transform: rotate(180deg);
            bottom: unset;
          }
        }
      }
      &.bottom-right{
        top: 100%;
        transform: translateX(-400px);
        left: 50%;
        .content-area {
          &:after{
            top: -6px;
            left: 95%;
            transform: rotate(180deg);
            bottom: unset;
          }
        }
      }
      &.bottom-left{
        top: 100%;
        transform: translateX(-200px);
        right: 50%;
        .content-area {
          &:after{
            top: -6px;
            right: 95%;
            transform: rotate(180deg);
            bottom: unset;
            left: 20px;
          }
        }
      }
      .content-area {
        background-color: #c16aaf;
        color: #fff;
        text-align: left;
        padding: 15px;
        border-radius: 6px;
        width: 100%;
        position: relative;
        box-shadow: 2px 2px 4px rgba(0,0,0,.3);
        -webkit-box-shadow: 2px 2px 4px rgba(0,0,0,.3);
        -moz-box-shadow: 2px 2px 4px rgba(0,0,0,.3);
        text-shadow: -1px -1px rgba(0,0,0,.15);
        line-height: 22px;
        &:before {
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          bottom: -8px;
          left: 50%;
          margin-left: -10px;
        }
        &:after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          bottom: 45%;
          left: -3px;
          margin-left: -8px;
          border-top-color: #c16aaf;
          border-top-style: solid;
          border-top-width: 6px;
          transform: rotate(90deg);
        }
        a {
          color: #fff;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .btn-secondary {
    color: #fff;
    height: 60px;
    width: 160px;
    border: 1px solid #ff5100;
    background-color: #fb7c41;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#fb7c41,endColorstr=#ff5100);
    background-image: -moz-linear-gradient(center top,#fb7c41,#ff5100);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fb7c41), to(#ff5100));
    padding: 0.375rem 1.75rem;
    line-height: 60px;
    padding: 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;

    &:hover{
      color: #fff;
      background-color: #ff5100;
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ff5100,endColorstr=#ff5100);
      background-image: -moz-linear-gradient(center top,#ff5100,#ff5100);
      background-image: -webkit-gradient(linear,0% 0%,0% 100%,from(#ff5100),to(#ff5100));
      border: 1px solid #ff5100;
    }
  }
  .btn-primary {
    color: #fff;
    height: 40px;
    width: 160px;
    border: 1px solid #009cff;
    background-color: #4db9fe;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#4db9fe,endColorstr=#009cff);
    background-image: -moz-linear-gradient(center top,#4db9fe,#009cff);
    background-image: -webkit-gradient(linear,0% 0%,0% 100%,from(#4db9fe),to(#009cff));
    box-shadow: 0 3px 6px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.3);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0,.3);
    padding: .375rem 1.75rem;
    line-height: 40px;
    padding: 0;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    &:hover {
      color: #fff;
      background-color: #009cff;
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#009cff,endColorstr=#009cff);
      background-image: -moz-linear-gradient(center top,#009cff,#009cff);
      background-image: -webkit-gradient(linear,0% 0%,0% 100%,from(#009cff),to(#009cff));
      border: 1px solid #009cff;
    }
  }
  .special {
    margin-bottom: -40px;
    .listing {
      padding: 15px;
      background-color: #c16aaf;
      box-shadow: 0 5px 10px rgba(0,0,0,.1);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-shadow: 0 1px 1px rgba(0,0,0,.2);
      text-transform: uppercase;
      text-align: center;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      h2 {
        margin: 0;
        line-height: 30px;
        font-size: 30px;
      }
      p {
        text-transform: none;
        margin: 5px 0 0;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .intro {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    &:before {
      content: "";
      background: url(/img/pricing/intro-bg-2.svg) no-repeat center bottom;
      background-size: 115%;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      opacity: .7;
    }
    .header {
      h2 {
        font-size: 42px;
        font-weight: 700;
        margin: 0;
      }
      p {
        margin-top: 10px;
        font-size: 19px;
        font-weight: 300;
      }
    }
    .paid-feat {
      margin-bottom: 0;
      background-color: #fcfcfc;
      .icon {
        margin-bottom: 15px;
      }
      .text {
        font-size: 18px;
        font-weight: 300;
        line-height: 31px;
        color: #06112f;
      }
    }
  }
  .price-container {
    padding-top: 30px;
    padding-bottom: 30px;
    .toggle-container {
      margin-top: 40px;
      margin-bottom: 30px;
      span {
        color: #7e8493;
        font-size: 20px;
        font-weight: 400;
        padding: 0 5px;
        position: relative;
        img {
          position: absolute;
          top: -40px;
        }
      }
      span.selected {
        color: #2ac5f4;
      }
      .price-switch {
        cursor: pointer;
      }
      .price-switch.selected {
        cursor: default;
      }
      .toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 65px;
        height: 34px;
        display: inline-block;
        position: relative;
        border-radius: 50px;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: #1e9ebb;
        transition: background-color ease .3s;
        top: 11px;
        margin: 0 15px;
        box-shadow: 0 0 14px rgba(43,163,200,.3);
        -webkit-box-shadow: 0 0 14px rgba(43,163,200,.3);
        -moz-box-shadow: 0 0 14px rgba(43,163,200,.3);
        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: 2;
          width: 28px;
          height: 28px;
          background: #fff;
          left: 4px;
          top: 3px;
          border-radius: 50%;
          font: 10px/28px Helvetica;
          text-transform: uppercase;
          font-weight: 700;
          text-indent: -22px;
          word-spacing: 37px;
          color: #fff;
          text-shadow: -1px -1px rgba(0,0,0,.15);
          white-space: nowrap;
          box-shadow: 0 1px 2px rgba(0,0,0,.2);
          -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
          -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
          transition: all cubic-bezier(.3,1.5,.7,1) .3s;
        }
        &:checked {
          background-color: #1e9ebb;
          &:before {
            left: 32px;
          }
        }
      }
    }
    .prices {
      .price {
        box-shadow: 0 2px 6px rgba(0,0,0,.15);
        -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.15);
        -moz-box-shadow: 0 2px 6px rgba(0,0,0,.15);
        border-radius: 11px;
        -webkit-border-radius: 11px;
        -moz-border-radius: 11px;
        background-color: #fff;
        padding-bottom: 35px;
        margin-bottom: 20px;
        position: relative;
        .ribbon {
          width: 130px;
          height: 130px;
          overflow: hidden;
          position: absolute;
          &::before {
            position: absolute;
            z-index: 0;
            content: '';
            display: block;
            border: 5px solid #e74a00;
          }
          &::after {
            position: absolute;
            z-index: 0;
            content: '';
            display: block;
            border: 5px solid #e74a00;
          }
          span {
            position: absolute;
            display: block;
            width: 225px;
            padding: 8px 0;
            background-color: #ff8e59;
            box-shadow: 0 5px 10px rgba(0,0,0,.1);
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            text-shadow: 0 1px 1px rgba(0,0,0,.2);
            text-transform: uppercase;
            text-align: center;
            a {
              color: #fff;
              text-decoration: none;
            }
          }
        }
        .ribbon.extra {
          width: 150px;
          height: 150px;
          &:before {
            border: 7px solid #8b3a7a;
          }
          &:after {
            border: 7px solid #8b3a7a;
          }
        }
        .ribbon-top-left {
          top: -10px;
          left: -10px;
          &::before {
            border-top-color: transparent;
            border-left-color: transparent;
            top: 0;
            right: 0;
          }
          &::after {
            border-top-color: transparent;
            border-left-color: transparent;
            bottom: 0;
            left: 0;
          }
          span {
            right: -35px;
            top: 35px;
            transform: rotate(-45deg);
          }
        }
        .ribbon-top-left.extra {
          top: -15px;
          left: -15px;
          span {
            padding-right: 22px;
            right: -38px;
            width: 235px;
            z-index: 4;
          }
        }
        .name {
          color: #06112f;
          font-size: 20px;
          font-weight: 600;
          line-height: 63px;
          border-bottom: 1px solid #e1e1e1;
          padding: 20px 0;
        }
        .price-area {
          font-size: 38px;
          font-weight: 600;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .period {
            align-self: center;
            font-size: 20px;
            margin-top: 8px;
          }
          .currency {
            color: #a8abb2;
            font-size: 25px;
            margin-right: 2px;
            margin-top: 3px;
            sup {
              top: -4px;
            }
          }
        }
        .price-area.old-price {
          text-decoration: line-through;
          margin-bottom: -25px;
          color: rgba(65,64,66,.5);
        }
        .pricing-panel {
          padding: 0 40px;
        }
        .pricing-panel.light-gray {
          font-weight: 400;
          .savings {
            color: #24b47e;
          }
        }
        .ideal {
          padding: 15px 20px;
          border-bottom: 1px solid #e1e1e1;
          .head {
            font-size: 15px;
            line-height: 29px;
            color: #343434;
            font-weight: 600;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            color: #06112f;
          }
        }
        .features {
          padding: 15px 20px 0;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 300;
          line-height: 26px;
          color: #06112f;
          .bold {
            font-weight: 600;
          }
          ul {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
            li {
              font-size: 14px;
              color: #06112f;
              margin: 0;
              &:before {
                content: "\2022";
                color: #2cc6f5;
                font-weight: 700;
                display: inline-block;
                width: 25px;
                margin-left: 0;
                font-size: 23px;
                top: 1px;
                position: relative;
              }
              a {
                text-decoration: none;
                color: #a8abb2;
              }
            }
          }
          ul.missing-single {
            padding-bottom: 0;
          }
          ul.double {
            columns: 2;
          }
          .vc_toggle{
            &.vc_toggle_arrow{
              padding: 8px 15px;
              margin-bottom: 10px;
              border-radius: 8px;
            }
            .vc_toggle_title{
              h5{
                margin-bottom: 5px;
                font-size: 18px;
              }
            }
            .vc_toggle_content{
              margin-top: 5px;
              line-height: 20px;
            }
          }
        }
        .comparison-link {
          text-align: center;
          margin-bottom: 10px;
          position: absolute;
          bottom: 0px;
          left: calc(50% - 100px);
          a {
            font-size: 14px;
            line-height: 29px;
            color: #343434;
            font-weight: 500;
            text-decoration: none;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            div {
              display: inline-block;
            }
            img {
              margin-left: 7px;
            }
          }
        }
        .cta {
          .btn-secondary {
            height: 40px;
            line-height: 40px;
          }
        }
      }
      .price.dark-shadow {
        box-shadow: 0 2px 6px rgba(0,0,0,.6);
      }
    }
  }

  .includes {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    .container-lg {
      max-width: 1029px;
    }
    .header {
      margin-bottom: 44px;
      h2 {
        font-size: 42px;
        font-weight: 700;
        span {
          display: block;
        }
      }
    }
    .paid-feat {
      margin-bottom: 75px;
      .icon {
        margin-bottom: 15px;
      }
      .text {
        font-size: 18px;
        font-weight: 300;
        line-height: 31px;
        color: #06112f;
      }
    }
  }
  .testimonials {
    padding-top: 50px;
    padding-bottom: 100px;
    background-color: #fff;
    h3 {
      font-size: 40px;
      font-weight: 600;
      span {
        display: block;
      }
    }
    .testimonial-area {
      .testimonial {
        border: 1px solid #f2f2f2;
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        box-shadow: 0 15px 30px rgba(22,10,28,.3);
        -webkit-box-shadow: 0 15px 30px rgba(22,10,28,.3);
        -moz-box-shadow: 0 15px 30px rgba(22,10,28,.3);
        max-width: 436px;
        position: relative;
        padding: 33px 37px 33px 54px;
        margin: 0 auto;
        .profile {
          position: absolute;
          left: -22px;
          top: 12px;
          img {
            position: relative;
            z-index: 15;
            border-radius: 56px;
            -webkit-border-radius: 56px;
            -moz-border-radius: 56px;
          }
          &:before {
            content: "";
            position: absolute;
            z-index: 1;
          }
        }
        .profile.bg-1 {
          &:before {
            background: url(/img/homepage/test-bg-1.svg) no-repeat center center;
            background-size: 63px 63px;
            width: 63px;
            height: 63px;
            top: -8px;
            right: -6px;
          }
        }
        .profile.bg-2 {
          &:before {
            background: url(/img/homepage/test-bg-2.svg) no-repeat center center;
            background-size: 65px 65px;
            width: 65px;
            height: 65px;
            top: -11px;
            right: -13px;
          }
        }
        .profile.bg-3 {
          &:before {
            background: url(/img/homepage/test-bg-3.svg) no-repeat center center;
            background-size: 62px 72px;
            width: 62px;
            height: 72px;
            top: -16px;
            right: -25px;
          }
        }
        .profile.bg-4 {
          &:before {
            background: url(/img/homepage/test-bg-4.svg) no-repeat center center;
            background-size: 66px 66px;
            width: 66px;
            height: 66px;
            top: -10px;
            right: -11px;
          }
        }
        .profile.bg-5 {
          &:before {
            background: url(/img/homepage/test-bg-5.svg) no-repeat center center;
            background-size: 77px 75px;
            width: 77px;
            height: 75px;
            top: -17px;
            right: -20px;
          }
        }
        .profile.bg-6 {
          &:before {
            background: url(/img/homepage/test-bg-6.svg) no-repeat center center;
            background-size: 54px 54px;
            width: 54px;
            height: 54px;
            top: 11px;
            right: -12px;
          }
        }
        .text {
          font-size: 16px;
          font-weight: 300;
          line-height: 27px;
          font-style: italic;
          .bold {
            font-weight: 600;
          }
        }
        .person {
          font-size: 16px;
          font-weight: 600;
          margin-top: 15px;
          color: #06112f;
        }
        .company {
          font-size: 16px;
          font-weight: 300;
          color: #787f8f;
          .title {
            font-weight: 500;
          }
        }
      }
    }
  }
  .faq {
    padding-top: 50px;
    padding-bottom: 55px;
    background-color: #fbfbfb;
    .header {
      margin-bottom: 35px;
      h3 {
        font-size: 42px;
        font-weight: 700;
      }
    }
    .question-area {
      background-color: #fff;
      border: 1px solid #ececec;
      border-radius: 11px;
      -webkit-border-radius: 11px;
      -moz-border-radius: 11px;
      box-shadow: 0 6px 19px rgba(0,0,0,.06);
      -webkit-box-shadow: 0 6px 19px rgba(0,0,0,.06);
      -moz-box-shadow: 0 6px 19px rgba(0,0,0,.06);
      padding: 30px 39px;
      margin-bottom: 10px;
      .question {
        font-size: 20px;
        font-weight: 600;
      }
      .answer {
        margin-top: 20px;
        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 37px;
        }
      }
      .icon {
        background: url(/img/pricing/faq-plus.svg) no-repeat center center;
        background-size: 36px 36px;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }
      .icon.open {
        background: url(/img/pricing/faq-minus.svg) no-repeat center center;
      }
    }
  }
  #compare {
    .features {
      .features-cat {
        width: 100%;
        .features-row {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;
          font-size: 16px;
          line-height: 18px;
          align-items: center;

          .row-title {
            flex: 1;
            padding-left: 20px;
          }

          .column-check {
            flex: 1;
            text-align: center;
            padding: 0 20px;
          }
        }

        .features-row.cat {
          border-bottom: 1px solid #e5e5e5;
          color: #242a35;
          margin-top: 35px;

          .row-title {
            font-size: 18px;
            font-weight: 700;
          }

          .column-check {
            .plan-name {
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  #compare {
    .table {
      .table-cat {
        width: 100%;
        .table-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          height: auto;
          padding-top: 15px;
          padding-bottom: 15px;
          transition: 140ms;
          font-size: 16px;
          line-height: 18px;
          align-items: center;
          .cta {
            .btn-secondary {
              height: 40px;
              line-height: 40px;
            }
          }
          .row-title {
            flex: 1;
            padding-left: 20px;
            div {
              display: inline-block;
            }
          }
          .column-check {
            flex: 1;
            text-align: center;
            padding-right: 20px;
            padding-left: 20px;
            .nope {
              width: 20px;
              height: 1px;
              background-color: #c0c6d9;
              margin: 0 auto;
            }
          }
        }
        .table-row.hover {
          &:hover {
            background-color: #f5f7fa;
          }
        }
        .table-row.cat {
          height: auto;
          padding-bottom: 10px;
          border-bottom: 1px solid #e5e5e5;
          color: #242a35;
          align-items: flex-start;
          background: rgb(244,244,244);
          background: linear-gradient(0deg, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 100%);
          margin-top: 35px;
          .row-title {
            font-size: 18px;
            font-weight: 700;
          }
          .column-check {
            .plan-name {
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .video-frame-backdrop{
    z-index: 200;
    background-color: rgba(51, 51, 51, 0.392);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .video-frame{
    position: fixed;
    z-index: 210;
    left: 40%;
    margin-left: -20%;
    width: 60%;
    top: 200px;
    height: 500px;
    padding-top: 15px;
    .frame-close-btn{
      cursor: pointer;
      right: -10px;
      top: -13px;
      position: absolute;
      width: 26px;
      height: 26px;
      border: 1px solid #333;
      border-radius: 50%;
      background: #fff;
      text-align: center;
      padding: 0;
    }
  }
  .plan-wrapper {
    @media (min-width: 992px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}


@media(max-width:991.98px) {
  .pricing_page {
    .intro {
      &:before {
        background-size: 1267px 164px;
      }
      padding-top: 50px;
      padding-bottom: 25px;
    }
    .price-container {
      padding-top: 0;
      .prices {
        .price {
          .features {
            ul.double {
              columns: 1;
            }
          }
        }
      }
    }
    .api-price-container {
      padding-top: 0;
      .prices {
        .price {
          .features {
            ul.double {
              columns: 1;
            }
          }
        }
      }
      .modal {
        .modal-lg {
          .signup-area {
            display: block;
            .input-area {
              overflow-y: visible;
              height: inherit;
              padding: 15px 0;
              top: 0;
              .sucess-message {
                line-height: 16px;
              }
              .intro {
                display: block;
                line-height: 25px;
                margin: 10px 0 15px;
                font-size: 25px;
              }
              .name {
                display: block;
                line-height: 16px;
                label {
                  display: none;
                }
              }
              .email {
                display: block;
                line-height: 16px;
                height: 75px;
                label {
                  display: none;
                }
                input {
                  border: 1px solid #eee;
                  border-radius: 15px;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  height: 45px;
                  width: 100%;
                  margin: 0 auto;
                  padding: 0 10px;
                  &:focus {
                    border: 1px solid #eee !important;
                  }
                }
              }
              .submit {
                display: block;
              }
              .submit-btn {
                position: relative;
                top: inherit;
                right: inherit;
                margin: 0 auto;
                display: block;
              }
            }
          }
        }
      }
    }
    .includes {
      .header {
        h2 {
          span {
            display: inline;
          }
        }
      }
    }
    .testimonials {
      padding-top: 50px;
    }
    .faq {
      padding-bottom: 50px;
    }
  }

}
@media(max-width:1199.98px) {
  .table {
    overflow-x: auto;
    .table-cat {
      min-width: 1000px;
    }
  }
  .pricing_page {
    .price-container {
      .modal {
        .modal-lg {
          &:before {
            background-size: 903px 265px;
          }
        }
      }
    }
    .api-price-container {
      .modal {
        .modal-lg {
          &:before {
            background-size: 903px 265px;
          }
        }
      }
    }
  }
}

.demo-form-wrapper{
  position: fixed;
  right: -1000px;
  top: 0;
  height: 100%;
  width: 500px;
  max-width: 96%;
  background: #f0f0f0;
  border: 2px solid #e4e4e4;
  overflow: auto;
  padding: 150px 15px 35px;
  transition: right 1s;

  &.visible{
    right: 0px;
  }

  &.submitted{
    .demo-form-fields{
      display: none;
    }
    .thanks{
      display: block;
    }
  }

  .thanks{
    display: none;
  }

  .demo-form-cancel{
    position: absolute;
    right: 14px;
    top: 150px;
    cursor: pointer;
  }
}

.demo-form{
  width: 500px;
  max-width: 95%;
  margin: 0 auto;
  .select_container{
    &:before{
      display: none;
    }
    &:after{
      display: none;
    }
  }
  .ms-select{
    height: auto;
    background-image: none;
    background: transparent;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 0 5px;
    color: #303942;
    &:hover{
      border-color: #9ca0a0!important;
    }
    select{
      border-radius: 0px;
      background: transparent;
      border: none;
      font-size: 13px;
      height: 30px;
      padding: 5px 35px 5px 10px;
      width: 100%;
      outline: none;
      color: #293b44;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      height: 140px;
      visibility: visible;
    }
  }
  .form {
    max-width: calc(100vw - 40px);
    width: 500px;
    height: auto;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #fff;
    margin: 3% auto;
    padding: 20px 30px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 5px solid #ccc;
  }
  form:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 8px;
      background: #c4e17f;
      border-radius: 5px 5px 0 0  ;
      background: rgba(196,225,127,1);
      background: -moz-linear-gradient(left, rgba(196,225,127,1) 0%, rgba(196,225,127,1) 20%, rgba(247,253,202,1) 20%, rgba(247,253,202,1) 40%, rgba(254,207,113,1) 40%, rgba(254,207,113,1) 60%, rgba(240,119,108,1) 60%, rgba(240,119,108,1) 80%, rgba(219,157,190,1) 80%, rgba(219,157,190,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(196,225,127,1)), color-stop(20%, rgba(196,225,127,1)), color-stop(20%, rgba(247,253,202,1)), color-stop(40%, rgba(247,253,202,1)), color-stop(40%, rgba(254,207,113,1)), color-stop(60%, rgba(254,207,113,1)), color-stop(60%, rgba(240,119,108,1)), color-stop(80%, rgba(240,119,108,1)), color-stop(80%, rgba(219,157,190,1)), color-stop(100%, rgba(219,157,190,1)));
      background: -webkit-linear-gradient(left, rgba(196,225,127,1) 0%, rgba(196,225,127,1) 20%, rgba(247,253,202,1) 20%, rgba(247,253,202,1) 40%, rgba(254,207,113,1) 40%, rgba(254,207,113,1) 60%, rgba(240,119,108,1) 60%, rgba(240,119,108,1) 80%, rgba(219,157,190,1) 80%, rgba(219,157,190,1) 100%);
      background: -o-linear-gradient(left, rgba(196,225,127,1) 0%, rgba(196,225,127,1) 20%, rgba(247,253,202,1) 20%, rgba(247,253,202,1) 40%, rgba(254,207,113,1) 40%, rgba(254,207,113,1) 60%, rgba(240,119,108,1) 60%, rgba(240,119,108,1) 80%, rgba(219,157,190,1) 80%, rgba(219,157,190,1) 100%);
      background: -ms-linear-gradient(left, rgba(196,225,127,1) 0%, rgba(196,225,127,1) 20%, rgba(247,253,202,1) 20%, rgba(247,253,202,1) 40%, rgba(254,207,113,1) 40%, rgba(254,207,113,1) 60%, rgba(240,119,108,1) 60%, rgba(240,119,108,1) 80%, rgba(219,157,190,1) 80%, rgba(219,157,190,1) 100%);
      background: linear-gradient(to right, rgba(196,225,127,1) 0%, rgba(196,225,127,1) 20%, rgba(247,253,202,1) 20%, rgba(247,253,202,1) 40%, rgba(254,207,113,1) 40%, rgba(254,207,113,1) 60%, rgba(240,119,108,1) 60%, rgba(240,119,108,1) 80%, rgba(219,157,190,1) 80%, rgba(219,157,190,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c4e17f', endColorstr='#db9dbe', GradientType=1 );
  }
  .form h2 {
      margin: 18px 0;
      padding-bottom: 10px;
      width: 210px;
      color: #ff5100;
      font-size: 22px;
      border-bottom: 3px solid #ff5501;
      font-weight: 600;
      margin-bottom: 30px;
  }
  input {
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #bebed2;
    transition: all .3s;
    border-radius: 4px;
    height: 35px;
  }
  .form p:before {
      content: attr(type);
      display: block;
      margin: 10px 0 0;
      font-size: 13px;
      color: #5a5a5a;
      float: left;
      width: 40%;
      transition: all .3s;
  }
  button {
      padding: 8px 12px;
      margin: 8px 0 0;
      font-family: 'Montserrat', sans-serif;
      border: 2px solid #78788c;
      background: 0;
      color: #5a5a6e;
      cursor: pointer;
      transition: all .3s;
  }
  button:hover {
      background: #78788c;
      color: #fff;
  }
  .tright{
      text-align: right;
  }
  .ui-menu{
      max-height: 150px;
      overflow: auto;
  }
  .ui-menu .ui-menu-item{
      padding:5px;
      font-size: 14px;
  }
  .relative{
      position: relative;
  }
  .relative i.fa:before{
      color: #444;
      padding: 10px;
      position: absolute;
      left: -3px;
      text-align: center;
  }

  .relative i.fa{
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      text-align: center;
      border-radius: 0 4px 4px 0;
      width: 0;
      height: 0;
      z-index: 99;
      border-left: 20px solid transparent;
      border-right: 30px solid #ccc;
      border-bottom: 34px solid #ccc;
      transition: all 0.15s ease-in-out;

  }
  .form-control:focus{
      border-color: #ff5100;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgb(30, 102, 195);
  }
  .relative input:focus + i.fa{
      border-left: 20px solid transparent;
      border-right: 30px solid #ff5100;
      border-bottom: 34px solid #ff5100;
  }
  .relative input:focus + i.fa:before{
      color: #fff;
  }
  .input-group .form-control:not(:first-child):not(:last-child),
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child){
      border-radius: 0 4px 4px 0;
  }
  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
      background-color: #fff;
  }

  /* --- Thanks Message Popup --- */
  .thanks{
    height: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 13px -10px #000;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    color: #333;
  }

  /* --- Animated Buttons --- */
  .movebtn{
      background-color: transparent;
      display:inline-block;
      width:100px;
      background-image: none;
      padding: 8px 10px;
      margin-bottom:20px;
      border-radius: 0;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s;
      -webkit-transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.5, 1.65, 0.37, 0.66);
  }
  .movebtnre {
      border: 2px solid #ff5501;
      box-shadow: inset 0 0 0 0 #ff5501;
      color:#ff5501;
  }
  .movebtnsu {
      border: 2px solid #ff5100 !important;
      box-shadow: inset 0 0 0 0 #ff5100 !important;
      color: #ff5100;
  }
  .movebtnre:focus,
  .movebtnre:hover,
  .movebtnre:active {
      background-color: transparent;
      color: #FFF;
      border-color: #ff5501;
      box-shadow: inset 96px 0 0 0 #ff5501;
  }
  .movebtnsu:focus,
  .movebtnsu:hover,
  .movebtnsu:active {
      background-color: transparent;
      color: #FFF;
      border-color: #ff5100;
      box-shadow: inset 96px 0 0 0 #ff5100 !important;
  }


  /* --- Media Queries --- */

  @media only screen and (max-width: 600px) {
      p:before{
          content: attr(type);
          width: 100%
      }
      input{
          width: 100%;
      }
  }
}

.product-type-selector{
  .select_container{
    background-color: #fc6d2a;
    outline: none !important;
    border-radius: 6px;
    max-width: 100%;
    margin: 0 auto;
    select{
      background-color: transparent !important;
      color: #fff !important;
      max-width: 100%;
      visibility: visible;
      &:focus{
        outline: none !important;
        background-color: transparent !important;
        color: #fff !important;
      }
    }
    &:before{
      width: 15px !important;
      background-color: #fc6d2a !important;
    }
    &:after{
      right: 13px !important;
    }
    &:hover{
      background-color: #fc6d2a !important;
    }
  }
  #product-select{
    outline: none !important;
    border-radius: 0.5rem;
    margin-top: 10px;
    color: #000;
    cursor: pointer;
    height: 40px;
    padding-right: 20px !important;
    background-color: transparent !important;
    color: #fff !important;
  }
}