.pilot-page{
  .white-paper-section{
    margin: 10px 0 50px 0px;
    background: #fff;
    box-shadow: 0 3px 3px 0 #dbdede;
    min-height: 70px;

    .pdf-image{
      max-width: 400px;
    }
  }
}