.exp-page{
  button{
    border-color: #fb7c41;
    box-shadow: 1px 4px 27px 0 #33333347;

    &:hover{
      border-color: #fb7c41;
      background-color: #fb6621;
      color: #fff;
    }
  }
  .banner-info{
    margin: -32px 0 5em 0;
    background: radial-gradient(at 50% -20%, #87b2c7, #2f414a) fixed;
    position: relative;
    background-size: cover;
    background-position: center center;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    padding: 160px 0 185px 0;
    height: auto;
    color: #fff;
    h1{
      font-size: 3em;
      color: #fff;
    }

    p{
      font-size: 1.5em;
    }
  }

  .section{
    padding-top: 80px;
    padding-bottom: 20px;
    position: relative;
    background-color: #fff;

    &.bg-light{
      background-color: #f8f9fa!important;
    }

    ul{
      padding-left: 0;
    }

    img{
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.012), 0 10px 50px rgba(0, 0, 0, 0.034);
      border-radius: 10px;
    }
  }

  .service-boxed{
    border: 1px solid #ececec;
    padding: 10px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    box-shadow: 0 10px 50px rgba(20, 51, 81, 0.031), 0 10px 50px rgba(0, 0, 0, 0.057);
    text-align: center;
  }
  .service-icon {
    width: 60px;
    margin-bottom: 20px;
  }
  .service-body {
    h5{
      margin-bottom: 20px;
      font-size: 20px;
    }
    p {
      margin-bottom: 0;
      line-height: 26px;
    }
  }

  .more-features{
    padding: 0px 0 20px 0;
  }
  .preference-subtitle {
    line-height: 24px;
  }
}