@import "includes/main";
@import "includes/home";
@import "includes/pricing";
@import "includes/testimonials";
@import "includes/tabs.scss";

@import "includes/media.scss";
@import "includes/retrospective";
@import "includes/getscrumban";
@import "includes/pilot";
@import "includes/exp";
@import "includes/course";
@import "includes/legal";
@import "includes/iframe";
@import "includes/bootstrap";
@import "includes/responsive";
