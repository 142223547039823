.tabs-group{
  margin: 60px 0;
}

.sdtabs {
  background-color: #fff8fb;
  border-radius: 12px;
  padding: 60px 40px;
  max-width: 85vw;
  margin: 0 auto;

  .sdtab-content{
    display: none;
    &:nth-of-type(1) {
      display: flex;
    }
  }
}
ul.sdtabs-nav {
  list-style: none;
  margin: 0;
  padding: 5px;
  overflow: auto;
}
ul.sdtabs-nav li {
  float: left;
  font-weight: bold;
  margin-right: 2px;
  padding: 8px 10px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}
ul.sdtabs-nav li:hover,
ul.sdtabs-nav li.active {
  border-bottom-color: #fb7c40;
}
.sdtabs-content {
  margin-top: 10px;
}
.sdtabs-nav li a {
  text-decoration: none;
}
.sdtab-content {
  padding: 10px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  width: 100%;
  -webkit-box-align: start;
  -ms-flex-align: start;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .tab-content{
    margin-right: 85px;
    min-width: 340px;
    max-width: 410px;

    .title{
      font-size: 22px;
      line-height: 32px;
    }
    .subtitle{
      margin-top: 15px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .js-build-in-item{
    transform: none !important;
  }
  .tab-terminal{
    position: relative;
    .bg_image{
      position: absolute;
      left: -210px;
      right: -120px;
      top: -130px;
      background-size: contain;
      bottom: -130px;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
  .tab-graphics{
    position: relative;
    border-radius: 16px;
    min-width: 0;
    max-width: 890px;
    box-shadow: 4px 4px 20px -5px #3333334f;
    padding: 20px;
    background: #fff;

    .graphic-dialog{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }

}