
.testimonial-wrapper {
  position: sticky;
  top: 30px;
  z-index: 2;
  &.testimonial-container {
    background-color: #fbfbfb;
    color: #5a5a5a;
    border-radius: 15px;
    margin: 30px auto;
    padding: 40px 20px 30px 20px;
    max-width: 768px;
    position: relative;
    border: 1px solid #dedede;
    height: auto !important;

    .fa-quote {
      color: rgba(247, 124, 67, 0.52);
      font-size: 28px;
      position: absolute;
      top: -15px;
    }

    .fa-quote-right {
      left: 5px;
    }

    .fa-quote-left {
      left: -21px;
    }

    .testimonial {
      line-height: 32px;
      text-align: justify;
      margin-bottom: 27px;
      margin-top: 20px;
      font-size: 22px;
    }

    .user {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbfbfb;
      border-radius: 12px;
      .username{
        font-size: 14px;
        font-weight: 700;
      }
      .role{
        font-size: 12px;
        font-weight: 600;
      }
      .read-more{
        font-size: 11px;
      }
    }

    .user .user-image {
      border-radius: 50%;
      height: 75px;
      width: 75px;
      object-fit: cover;
    }

    .user .user-details {
      margin-left: 10px;
    }

    .user .username {
      margin: 0;
    }

    .user .role {
      font-weight: normal;
      margin: 10px 0;
    }

    .progress-bar {
      background-color: #fa7c41;
      height: 2px;
      width: 100%;
      opacity: 0;
      animation: grow 10s linear infinite;
      transform-origin: left;
    }
  }
}
@keyframes grow {
  0% {
    transform: scaleX(0);
  }
}

@media (max-width: 768px) {
  .testimonial-container {
    padding: 20px 30px;
  }

  .fa-quote {
    display: none;
  }
}