@import "theme";

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.header-box {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 20px;
  background-color: #ffffff; /* white background */
  border: 1px solid #dee2e6;
  border-radius: 10px; /* rounded corners */
}

.header-box .btn {
  margin-top: 10px;
}
.logo {
  width: 100px; /* adjust as needed */
  margin-bottom: 20px; /* space between the logo and the title */
}
.c-title {
  font-size: 2.5em; /* adjust as needed */
  color: #333; /* adjust as needed */
  font-weight: bold; /* makes the text bold */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* adds a shadow to the text */
}
.c-subtitle {
  font-size: 1.5em; /* adjust as needed */
  color: #777; /* adjust as needed */
}
.c-subsubtitle {
  font-size: 1.2em; /* adjust as needed */
}
.banner {
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Other banner styles here */
}
.btn {
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #008000; /* green */
  border-color: #008000; /* green */
  color: #ffffff !important; /* white */

  &:hover {
    background-color: #006600; /* darker green */
    border-color: #006600; /* darker green */
  }
}

.btn-info2 {
  background-color: #808080; /* gray */
  border-color: #808080; /* gray */
  color: #ffffff; /* white */
}

.btn-info2:hover {
  background-color: #666666; /* darker gray */
  border-color: #666666; /* darker gray */
}

.cost-link {
  font-size: 11px; /* really small text */
  margin-top: 0px; /* reduced space above the link */
}
/* append your custom style below */
.question-area {
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: 11px;
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    box-shadow: 0 6px 19px rgba(0,0,0,.06);
    -webkit-box-shadow: 0 6px 19px rgba(0,0,0,.06);
    -moz-box-shadow: 0 6px 19px rgba(0,0,0,.06);
    padding: 30px 39px;
    margin-bottom: 10px;
    .question {
      font-size: 20px;
      font-weight: 600;
    }
    .answer {
      margin-top: 20px;
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 37px;
      }
    }
    .icon {
      background: url(/img/pricing/faq-plus.svg) no-repeat center center;
      background-size: 36px 36px;
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    .icon.open {
      background: url(/img/pricing/faq-minus.svg) no-repeat center center;
    }
  }