body {
  &.iframe-layout{
    .page-frame{
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      iframe{
        height: calc(100% - 170px);
        width: 100%;
        overflow: auto;
        border: none;
      }
    }
  }
}
